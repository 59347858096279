import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AppWrapper from 'components/AppWrapper';
import ErrorBoundary from 'components/ErrorBoundary';
import SubmissionFormView from 'views/SubmissionFormView';
import LoginView from 'views/LoginView';
import PasswordResetView from 'views/PasswordResetView';
import SubmissionDetailView from './SubmissionDetailView';
import SubmissionListView from 'views/SubmissionListView';
import SupportView from 'views/SupportView';
import PrivateRoute from 'components/PrivateRoute';
import { baseTheme } from 'shared/_base';
import tradeupLogo from './images/tradeup-logo.svg';
import submissionBackground from './images/submission-background.jpg';
import ProgramPausedView from 'shared/views/ProgramPausedView';

const theme = {
  ...baseTheme,
  primary: '#06038D',
  background: '#F4F7F6',
  images: {
    submissionBackground,
    tradeupLogo,
  },
};
const isProgramPaused = process.env.REACT_APP_PROGRAM_PAUSED === 'true';

const headerLinks = !isProgramPaused
  ? [
      {
        text: 'Submissions',
        to: '/submissions',
      },
      {
        text: 'FAQ',
        href: 'https://www.giant-bicycles.com/us/trade-up-faq',
      },
      {
        text: 'Support',
        to: '/support',
      },
    ]
  : undefined;

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppWrapper headerLinks={headerLinks}>
          <ErrorBoundary>
            {isProgramPaused ? (
              <ProgramPausedView tradeupLogo={tradeupLogo} partner="Giant" />
            ) : (
              <Switch>
                <Route exact path="/">
                  {/* todo: dummy iframe site to ensure this form works for all cases */}
                  <SubmissionFormView tradeupLogo={tradeupLogo} />
                </Route>
                <Route path="/login">
                  {/* todo: pass backgroundImg prop once we get an image */}
                  <LoginView redirectTo="/submissions" />
                </Route>
                <PrivateRoute exact path="/submissions" component={SubmissionListView} />
                <PrivateRoute
                  exact
                  path="/submissions/:submissionId"
                  component={SubmissionDetailView}
                />
                <Route exact path="/support">
                  <SupportView />
                </Route>
                <Route exact path="/passwordreset">
                  <PasswordResetView />
                </Route>
                <Redirect from="*" to="/" />
              </Switch>
            )}
          </ErrorBoundary>
        </AppWrapper>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);
