import { Component } from 'react';
import { withRouter, RouteComponentProps, Link, } from 'react-router-dom';
import styled from 'styled-components';
import api from 'utils/api';
import Loader from 'components/Loader';
import ErrorBoundary from 'components/ErrorBoundary';
import SubmissionStatus, { SubmissionProp as SSSubmissionProp } from 'components/submission/detail/SubmissionStatus';
import SellerInfo, { SubmissionProp as SISubmissionProp } from 'components/submission/detail/SellerInfo';
import BikeInfo, { SubmissionProp as BISubmissionProp } from 'components/submission/detail/BikeInfo';

const PageWrapper = styled.div`
  margin: 0 1rem;
  margin-top: 1rem;

  @media(min-width: ${({ theme }) => theme.breakpointMedium}) {
    margin: 0 auto;
    margin-top: 3rem;
    max-width: 60vw;
    min-width: 40rem;
  }
`;

const BackLink = styled(Link)`
  font-size: 18px;
`;

interface Submission extends SSSubmissionProp, SISubmissionProp, BISubmissionProp {}

type Props = RouteComponentProps<{ submissionId: string }>
interface State {
  submission: Submission | null
}

class SubmissionDetailView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { submission: null }
  }

  async componentDidMount() {
    const { submissionId } = this.props.match.params;
    const url = `/partners/submission/${submissionId}`;
    try {
      const submission = await api.get<Submission>(url);
      this.setState({ submission });
    } catch (error) {
      this.setState(() => { throw error });
    }
  }

  render() {
    const { submission } = this.state;
  
    if (!submission) {
      return <Loader />;
    }

    return (
      <PageWrapper>
        <BackLink to="/submissions">
          {'<'} Return to Submission List
        </BackLink>
        <ErrorBoundary>
          <SubmissionStatus submission={submission}/>
        </ErrorBoundary>
        <ErrorBoundary>
          {/* seller address is irrelevant, GPP sellers select a dealer to TradeUP */}
          <SellerInfo submission={submission} showSellerAddress={false}/>
        </ErrorBoundary>
        <ErrorBoundary>
          <BikeInfo submission={submission} />
        </ErrorBoundary>
      </PageWrapper>
    );
  }
}

export default withRouter(SubmissionDetailView);